import FieldElement from './field_element';
import countryCodeHelper from '../../show/helpers/country_code';
import OrderFormElement from '../../show/prototypes/order_form_element';
import ProductListElement from '../../show/prototypes/product_list_element';
import { LPSData } from '../../show/models/config';

function sortByRectTop(a, b) {
  if (a.rect.top < b.rect.top) return -1;
  if (a.rect.top > b.rect.top) return 1;
  return 0;
}

function FormElement(form) {
  this.form = form;
  this.orderForm = this.registerOrderForm(form);
  this.productList = this.registerProductList(form);

  this.init();
}

FormElement.prototype = Object.create({});

FormElement.prototype.registerProductList = function (formElement) {
  const productListElement = formElement.querySelector('[data-editable="productList"]');

  if (productListElement) {
    return new ProductListElement(this.form, productListElement);
  } else {
    return null;
  }
};

FormElement.prototype.registerOrderForm = function (formElement) {
  if (LPSData.hasIntegrationWithoutCreditCardFields()) {
    return null;
  }

  const orderFormElement = formElement.querySelector('[data-editable="orderForm"]');

  if (orderFormElement) {
    return new OrderFormElement(orderFormElement, this);
  } else {
    return null;
  }
};

FormElement.prototype.init = function () {
  var oThis = this,
    elements = Array.prototype.slice.call(this.form.elements);

  elements.forEach(function (element) {
    //Exclude stripe elements - wszystko przez to ze brane sa wszystkie elementy forma... i nie sa jawnie oznaczone
    if (element.className === '__PrivateStripeElement-input') {
      return;
    }

    var field = new FieldElement(element);

    oThis[field.name] = field;

    Array.prototype.push.call(oThis, field);
  });

  var formElements = Array.from(oThis);

  formElements.sort(sortByRectTop);

  for (var i = 0; i < formElements.length; i++) {
    var elem = formElements[i];
    elem.setZIndex(formElements.length - i);
  }

  this.form.addEventListener(
    'submit',
    function (e) {
      oThis.validate();
      e.preventDefault();
      e.stopPropagation();
      return false;
    },
    false,
  );

  countryCodeHelper.updateCountryCode(this.form);
};

FormElement.prototype.reportValidity = function () {
  var oThis = this,
    fieldsName = Object.keys(this.errors || {});

  fieldsName.forEach(function (name) {
    if (oThis[name]) {
      oThis[name].reportValidity(oThis.errors[name]);
    }
  });

  return !!fieldsName.length;
};

export default FormElement;
