import FormElement from './form_element';

function Forms() {}

Forms.prototype = {
  register: function (element) {
    var oThis = this,
      formSelectors = [
        'div[data-editable="webform"] > form',
        '[data-editable="webformNew"] form',
        '[data-editable="webinarNew"] form',
      ],
      formElements = element ? [element] : document.querySelectorAll(formSelectors.join(', '));

    (Array.prototype.slice.call(formElements) || []).forEach(function (formElement) {
      var form = new FormElement(formElement);

      Array.prototype.push.call(oThis, form);
      oThis[name] = form;
    });
  },
  namedItem: function (name) {
    return this[name];
  },
};

export default Forms;
