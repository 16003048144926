import $ from 'jquery';
import Forms from './prototypes/forms';
import { FontsHelper } from '../show/helpers/fonts';
import placeholderHelper from '../show/helpers/placeholder';
import webinarsHelper from '../show/helpers/webinars';
import socialHelper from '../editor/helpers/social';
import '../show/helpers/mobile';
import customCodeModifier from '../show/modifiers/custom_code';
import webformAppsModifier from '../show/modifiers/webform_apps';
import oldMobileHelper from '../show/helpers/old_mobile';
import thumbnailsHelper from '../show/helpers/thumbnails';
import firefoxHelper from '../show/helpers/firefox';
import hoverHelper from '../show/helpers/hover';
import customIframeHelper from '../show/helpers/custom_iframe';
import customPhoneHelper from '../show/helpers/custom_phone';
import webScreenerHelper from './helpers/webScreener';
import gdprFieldsModifier from '../show/modifiers/gdpr_fields';
import countdownTimerModifier from '../show/modifiers/countdown_timer';
import bodyModifier from '../show/modifiers/body';
import grBadge from '../show/modifiers/gr_badge';
import helperCookie from '../editor/helpers/cookie';
import { AutoFunnelModifier } from '../show/modifiers/autofunnel';
import { linksModifier } from '../show/modifiers/links';
import { FormItem } from '../../modifiers/formItem';
import { ErrorMonitor } from '../../monitors/errorMonitor/ErrorMonitor';
import { l10n } from '../show/helpers/l10n';
import { ViewName } from '../../enums/viewName';

const { grLpsInitialData } = window;
const { userId, crypto } = grLpsInitialData;

ErrorMonitor.init({
  view: ViewName.Preview,
  crypto,
});

ErrorMonitor.setUser(userId, crypto);

window.localStorage.removeItem('webform.preview');

const appPreview = {
  async initialize() {
    this.$document = $(document);
    this.$body = $('body');

    try {
      await oldMobileHelper.initialize();
    } catch (e) {}

    AutoFunnelModifier.modify(); //update as early as possible

    bodyModifier.modify();
    gdprFieldsModifier.replaceContent();
    FontsHelper.loadFonts();
    placeholderHelper.initialize();
    socialHelper.initialize();
    webinarsHelper.initialize();
    thumbnailsHelper.initialize();
    hoverHelper.initialize();
    webScreenerHelper.initialize();
    grBadge.initialize(true);

    linksModifier.initialize(true);
    this.initializeSubmitButton();
    this.registerForms();

    customPhoneHelper.initialize(window.grLpsInitialData.country_phone_codes);
    customCodeModifier.enableScripts();
    webformAppsModifier.enableScripts();
    countdownTimerModifier.enableScripts();
    firefoxHelper.initialize();
    customIframeHelper.initialize();
    FormItem.initialize(l10n());

    this.clearWebformAppSettings();
  },

  initializeSubmitButton() {
    this.$body.on('click', '[data-editable="webformNewButton"]', (e) => {
      e.preventDefault(); // Do nothing when submit button is clicked
    });
  },

  registerForms() {
    new Forms().register();
  },

  clearWebformAppSettings() {
    const { webformApps } = window.grLpsInitialData;

    // Usuń cookies pokazanych juz webformow zeby bylo widac jak dzialaja.
    webformApps.forEach((webformData) => {
      helperCookie.remove(`grwf2_${webformData.decodedWebformId}`);
    });
  },
};

appPreview.initialize();
