import Handles from '../../show/prototypes/handles';

var propertiesList = ['type', 'name', 'id'];
var rxErrorHas = new RegExp('(^|\\s+)error(\\s+|$)');
var rxErrorRemove = new RegExp('(?:^|\\s+)error');

function FieldElement(element) {
  var oThis = this,
    attrs = Array.prototype.slice.call(element.attributes) || [],
    rect = element.getBoundingClientRect();

  this.element = element;
  this.parentNode = this.element.parentNode;
  this.rect = {
    top: rect.top,
  };

  (function (_self) {
    //field blocker is necessary for editor, but not for user on showpage
    var fieldBlocker = _self.parentNode.querySelector('.field-blocker');

    if (fieldBlocker) {
      fieldBlocker.parentNode.removeChild(fieldBlocker);
    }
  })(this);

  Object.defineProperties(this, {
    validationMessage: {
      get: function () {
        return this.element.getAttribute('data-error-msg');
      },
      set: function (value) {
        this.element.setAttribute('data-error-msg', value);
      },
      enumerable: true,
    },
  });

  attrs.forEach(function (attr) {
    if (propertiesList.includes(attr.name)) {
      oThis[attr.name] = attr.value;
    }
  });

  new Handles(this);
}

FieldElement.prototype = Object.create({
  on: function (name, action) {
    if (!this.observer) {
      this.observer = {};
    }

    if (!this.observer[name]) {
      this.observer[name] = [];
    }

    if (this.observer[name].includes(action)) {
      this.observer[name][this.observer[name].indexOf(action)] = action;
    } else {
      this.observer[name].push(action);
    }
  },
  off: function (name, action) {
    var ix;

    if (!this.observer) {
      return;
    }
    if (!this.observer[name]) {
      return;
    }

    ix = this.observer[name].indexOf(action);

    if (-1 < ix) {
      this.observer[name].splice(ix, 1);
    }
  },
  notify: function (name) {
    var oThis = this,
      args;
    if (!this.observer) {
      return;
    }

    if (!this.observer[name]) {
      return;
    }

    args = Array.prototype.slice.call(args);

    this.observer[name].forEach(function (fn) {
      fn.apply(oThis, args);
    });
  },
});

FieldElement.prototype.reportValidity = function (msg) {
  if (msg) {
    this.setErrorMessage(msg);
  } else {
    this.unsetErrorMessage();
  }

  return !!msg;
};

FieldElement.prototype.setErrorMessage = function (customMessage) {
  var parent = this.parentNode;

  customMessage = customMessage || this.validationMessage;

  if (customMessage) {
    parent.setAttribute('data-error-msg', customMessage);
    if (!rxErrorHas.test(parent.className)) {
      parent.className = [parent.className, 'error'].join(' ').trim();
    }
  } else {
    this.unsetErrorMessage();
  }
};

FieldElement.prototype.unsetErrorMessage = function () {
  var parent = this.parentNode;

  parent.className = parent.className.replace(rxErrorRemove, '').trim();
  parent.removeAttribute('data-error-msg');
};

FieldElement.prototype.setZIndex = function (newZIndex) {
  var $element = $(this.element),
    webformItem = $element.closest('[data-editable]');
  this.zIndex = newZIndex;

  if (webformItem[0]) {
    webformItem[0].style.zIndex = newZIndex;
  }
};

export default FieldElement;
